import * as React from "react"
import { Grid, Flex } from "theme-ui"
import { Link } from "gatsby"
import md5 from "md5"
import { PageWrapper } from "../../components/modern/layout"

const TemplateCV1 = ({ pageContext }) => {
  const { cvs } = pageContext

  return (
    <PageWrapper title="CVs" pathname="/cv">
      <Grid sx={{ pt: `130px` }}>
        {cvs.map(({ slug, name }) => (
          <Flex key={slug} sx={{ gap: `0.5rem` }}>
            <Link to={`/cv/${slug}`}>{name}</Link>-
            <Link to={`/cv/${md5(slug)}`}>Anonymous CV</Link>
          </Flex>
        ))}
      </Grid>
    </PageWrapper>
  )
}

export default TemplateCV1
